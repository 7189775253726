
require.register("chart.js/src/charts/Chart.Doughnut.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "chart.js");
  (function() {
    'use strict';

module.exports = function(Chart) {

	Chart.Doughnut = function(context, config) {
		config.type = 'doughnut';

		return new Chart(context, config);
	};

};
  })();
});