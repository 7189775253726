
require.register("chart.js/src/charts/Chart.Scatter.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "chart.js");
  (function() {
    'use strict';

module.exports = function(Chart) {
	Chart.Scatter = function(context, config) {
		config.type = 'scatter';
		return new Chart(context, config);
	};
};
  })();
});