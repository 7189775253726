
require.register("date.js/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "date.js");
  (function() {
    /**
 * Expose `Date`
 */

module.exports = require('./lib/parser');
  })();
});