
require.register("strip-ansi/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "strip-ansi");
  (function() {
    'use strict';
var ansiRegex = require('ansi-regex')();

module.exports = function (str) {
	return typeof str === 'string' ? str.replace(ansiRegex, '') : str;
};
  })();
});