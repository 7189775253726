
require.register("chart.js/src/plugins/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "chart.js");
  (function() {
    'use strict';

module.exports = {};
module.exports.filler = require('./plugin.filler');
module.exports.legend = require('./plugin.legend');
module.exports.title = require('./plugin.title');
  })();
});