
require.register("remove-trailing-separator/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "remove-trailing-separator");
  (function() {
    var isWin = process.platform === 'win32';

module.exports = function (str) {
	var i = str.length - 1;
	if (i < 2) {
		return str;
	}
	while (isSeparator(str, i)) {
		i--;
	}
	return str.substr(0, i + 1);
};

function isSeparator(str, i) {
	var char = str[i];
	return i > 0 && (char === '/' || (isWin && char === '\\'));
}
  })();
});