
require.register("chart.js/src/elements/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "chart.js");
  (function() {
    'use strict';

module.exports = {};
module.exports.Arc = require('./element.arc');
module.exports.Line = require('./element.line');
module.exports.Point = require('./element.point');
module.exports.Rectangle = require('./element.rectangle');
  })();
});