
require.register("handlebars-helpers/lib/logging.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "handlebars-helpers");
  (function() {
    'use strict';

/**
 * [logging-helpers](https://github.com/helpers/logging-helpers).
 * @api public
 */

module.exports = require('logging-helpers');
  })();
});