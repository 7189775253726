
require.register("handlebars-helpers/lib/date.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "handlebars-helpers");
  (function() {
    'use strict';

/**
 * Expose `helpers`
 */

var helpers = module.exports;

/**
 * Expose `moment` helper
 * @exposes helper-date as moment
 * @api public
 */

helpers.moment = require('helper-date');
  })();
});