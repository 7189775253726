
require.register("create-frame/utils.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "create-frame");
  (function() {
    'use strict';

var utils = require('lazy-cache')(require);
var fn = require;
require = utils;

/**
 * Lazily required module dependencies
 */

require('define-property', 'define');
require('extend-shallow', 'extend');
require('isobject', 'isObject');
require = fn;

/**
 * Expose `utils` modules
 */

module.exports = utils;
  })();
});