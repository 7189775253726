
require.register("url/util.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "url");
  (function() {
    'use strict';

module.exports = {
  isString: function(arg) {
    return typeof(arg) === 'string';
  },
  isObject: function(arg) {
    return typeof(arg) === 'object' && arg !== null;
  },
  isNull: function(arg) {
    return arg === null;
  },
  isNullOrUndefined: function(arg) {
    return arg == null;
  }
};
  })();
});