
require.register("escape-string-regexp/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "escape-string-regexp");
  (function() {
    'use strict';

var matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;

module.exports = function (str) {
	if (typeof str !== 'string') {
		throw new TypeError('Expected a string');
	}

	return str.replace(matchOperatorsRe, '\\$&');
};
  })();
});