require.register("date.js/lib/maps.json", function(exports, require, module) {
  module.exports = {
  "op": {
    "plus": ["and", "plus", "+", "add", "on"],
    "minus": ["minus", "subtract"],
    "times": ["times", "multiply"],
    "divide": ["divide"]
  },
  "o": {
    "plus": ["at", "in", "past", "late", "later", "after", "next", "from", "start", "starting", "since", "coming"],
    "minus": ["last", "minus", "subtract", "ago", "before", "from"]
  },
  "n": {
    "0.25": ["quarter"],
    "0.5": ["half", "1/2", "half an"],
    "0": ["zero"],
    "1": ["one", "a", "an", "first"],
    "2": ["two", "second"],
    "3": ["three", "third"],
    "4": ["four", "fourth"],
    "5": ["five", "fifth"],
    "6": ["six", "sixth"],
    "7": ["seven", "seventh"],
    "8": ["eight", "eighth"],
    "9": ["nine", "ninth"],
    "10": ["ten", "tenth"],
    "11": ["eleven", "eleventh"],
    "12": ["twelve", "twelveth"],
    "13": ["thirteen", "thirteenth"],
    "14": ["fourteen", "fourteenth"],
    "15": ["fifteen", "fifteenth"],
    "16": ["sixteen", "sixteenth"],
    "17": ["seventeen", "seventeenth"],
    "18": ["eighteen", "eighteenth"],
    "19": ["nineteen", "nineteenth"],
    "20": ["twenty", "twentieth"],
    "30": ["thirty", "thirtieth"],
    "40": ["fourty", "fourtieth"],
    "50": ["fifty", "fiftieth"],
    "60": ["sixty", "sixtieth"],
    "70": ["seventy", "seventieth"],
    "80": ["eighty", "eightieth"],
    "90": ["ninety", "ninetieth"],
    "100": ["hundred", "hundreds", "hundredth"],
    "1000": ["thousand", "thousands", "thousandth", "k", "K"]
  },
  "t": {
  },
  "dt": {
    "s": ["second", "seconds", "s", "sec", "secs"],
    "m": ["minute", "minutes", "m", "min", "mins"],
    "h": ["hour", "hours", "h", "hr", "hrs"],
    "d": ["day", "days", "d", "dai"],
    "w": ["week", "weeks", "w", "wk", "wks"],
    "M": ["month", "months", "monthes", "M", "mo", "moon", "moons"],
    "y": ["year", "years", "y", "yr", "yrs"]
  },
  "T": {
    "t:,dt:=3h": ["later", "soon"],
    "t:=1d,dt:": ["st", "nd", "rd", "th", "st day", "nd day", "rd day", "th day"],
    "t:,dt:1w": ["st week", "nd week", "rd week", "th week"],
    "t:,dt:14d": ["day", "fortnight"],
    "t:=0h=0m=0s1mer,dt:": ["pm", "p.m", "p.m.", "noon"],
    "t:,dt:1d": ["tomorrow", "tmr"],
    "t:,dt:-1d": ["yesterday", "ytd"],
    "t:,0dt:": ["today"],
    "t:=2h=0m=0s1mer,dt:": ["afternoon"],
    "t:=6h=0m=0s0mer,dt:": ["dawn"],
    "t:=7h=0m=0s0mer,dt:": ["am", "a.m", "a.m."],
    "t:=7h=0m=0s1mer,dt:": ["evening"],
    "t:=8h=0m=0s0mer,dt:": ["morning"],
    "t:=9h=0m=0s1mer,dt:": ["tonight", "night"],
    "t:=0h=0m=0s0mer,dt:1d": ["midnight"],
    "t:,dt:=0w0wd": ["sunday", "sun"],
    "t:,dt:=0w1wd": ["monday", "mon"],
    "t:,dt:=0w2wd": ["tuesday", "tue", "tues"],
    "t:,dt:=0w3wd": ["wednesday", "wed"],
    "t:,dt:=0w4wd": ["thursday", "thu", "thur", "thurs"],
    "t:,dt:=0w5wd": ["friday", "fri"],
    "t:,dt:=0w6wd": ["saturday", "sat"],
    "t:1M=1d,dt:": ["january", "jan"],
    "t:2M=1d,dt:": ["february", "feb"],
    "t:3M=1d,dt:": ["march", "mar"],
    "t:4M=1d,dt:": ["april", "apr"],
    "t:5M=1d,dt:": ["may"],
    "t:6M=1d,dt:": ["june", "jun"],
    "t:7M=1d,dt:": ["july", "jul"],
    "t:8M=1d,dt:": ["august", "aug"],
    "t:9M=1d,dt:": ["september", "sept", "sep"],
    "t:10M=1d,dt:": ["october", "oct"],
    "t:11M=1d,dt:": ["november", "nov"],
    "t:12M=1d,dt:": ["december", "dec"],
    "t:12M25d,dt:": ["christmas"]
  },
  "f": {
    "1": ["once"],
    "2": ["twice"]
  }
}
;
});